import type { BasicProduct } from '~/modules/product/types/basic-product';
import { Link, useFetcher } from '@remix-run/react';
import { cn } from '~/lib/utils';
import { Button } from '~/components/ui/button';
import { useInRouterContext } from 'react-router';
import { gtagClient } from '~/lib/analytics/gtag.client';
import { useTranslate } from '~/modules/shared/providers/global-resources.provider';

type ProductCardProps = {
  product: BasicProduct;
  width?: string;
  categorySlug: string;
};

export function ProductCard({
  product,
  width,
  categorySlug,
}: ProductCardProps) {
  const inRouterContext = useInRouterContext();

  const liClasses = cn(
    'group relative mb-8 flex flex-none flex-col overflow-hidden rounded border border-gray-200 lg:w-auto',
    width ?? 'w-64',
  );

  if (inRouterContext) {
    // https://github.com/algolia/instantsearch/issues/5552
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return (
      <li className={liClasses}>
        <div key={product.id} className="h-full">
          <input type="hidden" name="productId" value={product.id} />
          <Link
            className="flex h-full flex-col"
            to={`/produkt/${categorySlug}/${product.slug}`}
            unstable_viewTransition
          >
            <CardContent product={product} />
          </Link>
        </div>
      </li>
    );
  }

  return <li className={liClasses}></li>;
}

function CardContent({ product }: { product: BasicProduct }) {
  const fetcher = useFetcher<unknown>();
  const { translate } = useTranslate('productCard');

  const mainImage = `https://res.cloudinary.com/curtains24/image/upload/f_auto/c_thumb,g_north,h_300,w_280/c_scale,w_280/visuals/${product.sku}_universal_v2.jpg`;
  const hoverImage = `https://res.cloudinary.com/curtains24/image/upload/f_auto/c_crop,h_600,w_700,x_100,y_610/c_thumb,h_300,w_280/c_scale,w_280/visuals/${product.sku}_universal_v2.jpg`;

  return (
    <>
      <div className="aspect-h-5 aspect-w-4 w-full overflow-hidden">
        <img
          src={mainImage}
          loading="lazy"
          className="h-full w-full object-cover object-top"
        />

        <img
          src={hoverImage}
          loading="lazy"
          className="absolute left-0 top-0 h-full w-full object-cover object-top opacity-0 transition-opacity duration-200 md:group-hover:opacity-100"
        />
        <div className="absolute left-0 top-0 h-full w-full">
          <div className="absolute bottom-0 right-0 mb-6 overflow-hidden rounded-l-lg bg-white text-center text-sm outline outline-2 outline-accent-20">
            <span className="block h-full w-full bg-accent px-2 py-0.5 text-xs uppercase text-accent-foreground md:text-sm">
              {translate('deliveryTime')}
            </span>
          </div>
        </div>
      </div>
      <div className="flex flex-grow flex-col justify-between p-4">
        <h3 className="my-1 w-full flex-grow overflow-hidden break-words text-card-foreground text-gray-700">
          {product.name}
        </h3>
        <div className="mb-4 mt-2 flex flex-none items-center text-headers">
          <span className="mr-2">ab</span>
          <span className="text-2xl font-bold">{product.price.unitPrice}</span>
          {/*<span className="ml-2 text-sm">{product.unit}</span>*/}
        </div>

        <div className="flex flex-none flex-col">
          <Button className="">{translate('configureButton')}</Button>

          <Button
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              fetcher.submit(
                { sku: product.sku, name: product.name },
                {
                  action: '/cart/add-sample',
                  method: 'post',
                },
              );

              gtagClient.addToCart({
                sku: 'SAMPLE',
                name: 'Sample',
                price: '0.00',
              });
            }}
            variant="accent"
            className="mt-4 w-full"
            type="button"
          >
            {translate('sampleButton')}
          </Button>
        </div>
      </div>
    </>
  );
}
